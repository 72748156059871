<template>
	<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
			id="mask0_220_8559"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="25"
			height="24"
		>
			<rect x="0.5" width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_220_8559)">
			<path
				d="M18.25 20.25C17.2 20.25 16.3125 19.8875 15.5875 19.1625C14.8625 18.4375 14.5 17.55 14.5 16.5C14.5 15.45 14.8625 14.5625 15.5875 13.8375C16.3125 13.1125 17.2 12.75 18.25 12.75C19.3 12.75 20.1875 13.1125 20.9125 13.8375C21.6375 14.5625 22 15.45 22 16.5C22 17.55 21.6375 18.4375 20.9125 19.1625C20.1875 19.8875 19.3 20.25 18.25 20.25ZM18.25 18.25C18.7333 18.25 19.1458 18.0792 19.4875 17.7375C19.8292 17.3958 20 16.9833 20 16.5C20 16.0167 19.8292 15.6042 19.4875 15.2625C19.1458 14.9208 18.7333 14.75 18.25 14.75C17.7667 14.75 17.3542 14.9208 17.0125 15.2625C16.6708 15.6042 16.5 16.0167 16.5 16.5C16.5 16.9833 16.6708 17.3958 17.0125 17.7375C17.3542 18.0792 17.7667 18.25 18.25 18.25ZM4.5 17.5V15.5H12.5V17.5H4.5ZM6.75 11.25C5.7 11.25 4.8125 10.8875 4.0875 10.1625C3.3625 9.4375 3 8.55 3 7.5C3 6.45 3.3625 5.5625 4.0875 4.8375C4.8125 4.1125 5.7 3.75 6.75 3.75C7.8 3.75 8.6875 4.1125 9.4125 4.8375C10.1375 5.5625 10.5 6.45 10.5 7.5C10.5 8.55 10.1375 9.4375 9.4125 10.1625C8.6875 10.8875 7.8 11.25 6.75 11.25ZM6.75 9.25C7.23333 9.25 7.64583 9.07917 7.9875 8.7375C8.32917 8.39583 8.5 7.98333 8.5 7.5C8.5 7.01667 8.32917 6.60417 7.9875 6.2625C7.64583 5.92083 7.23333 5.75 6.75 5.75C6.26667 5.75 5.85417 5.92083 5.5125 6.2625C5.17083 6.60417 5 7.01667 5 7.5C5 7.98333 5.17083 8.39583 5.5125 8.7375C5.85417 9.07917 6.26667 9.25 6.75 9.25ZM12.5 8.5V6.5H20.5V8.5H12.5Z"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
